<template>
  <Modal :showModal="showModal">
    <div
      class="relative flex flex-col bg-white dark:bg-cs-dark-background items-center rounded-[10px] p-[30px] pt-[55px] shadow-[0px_4px_4px_0px_rgba(182,182,182,0.10)]"
    >
      <IconClose
        class="absolute top-5 right-5 cursor-pointer self-end [&>g>path]:stroke-cs-black dark:[&>g>path]:stroke-white"
        @click="() => toggleModal(false)"
      />
      <NuxtImg
        class="w-[175px] h-auto object-cover"
        format="webp"
        loading="lazy"
        preload
        quality="100"
        src="/landing/register.svg"
      />
      <span class="w-[272px] mt-[10px] text-center text-[36px] text-cs-black dark:text-white font-bold leading-[43px]">{{
        $t('register.thankYou')
      }}</span>
      <p class="w-[278px] text-sm text-cs-black dark:text-white text-center mt-5 mb-10">
        {{ $t('landing.registerConfirmationMessage') }}
      </p>
      <span v-if="isResent" class="text-center text-sm text-cs-violet">{{
        $t('landing.registerConfirmationTimer', {number: countDown})
      }}</span>
      <div v-else class="flex flex-col items-center">
        <span class="text-center text-sm text-cs-black dark:text-white">{{ $t('landing.registerConfirmationResend') }} </span>
        <CommonLink
          :title="$t('landing.registerConfirmationAction')"
          class="underline tracking-normal font-normal"
          to="#"
          @click="resendEmail"
          >{{ $t('landing.registerConfirmationAction') }}
        </CommonLink>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import IconClose from 'assets/icons/close.svg'
import {useUserRegister} from '~/composables/auth'

interface ComponentProps {
  showModal: boolean
  toggleModal: Function
  email?: string
}

const props = defineProps<ComponentProps>()

const {$showErrorToast} = useNuxtApp()

const isResent = ref<boolean>(false)
const countDown = ref<number>(120)

const resendEmail = async () => {
  const {error} = await useUserRegister(props.email, null)
  if (error.value) {
    $showErrorToast(error)
  }
  isResent.value = true
  executeResendTask()
}

const executeResendTask = () => {
  const timerTask = setInterval(function () {
    if (countDown.value <= 0) {
      clearInterval(timerTask)
      isResent.value = false
    }
    countDown.value = countDown.value - 1
  }, 1000)
}
</script>
